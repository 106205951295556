import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthService } from './auth.service';
import { take } from 'rxjs/operators';
var AuthComponent = /** @class */ (function () {
    // public groupwareUri: string = environment.groupwareUri;
    // public logisticsProcessUri: string = environment.logisticsProcessUri;
    function AuthComponent(route, authService) {
        this.route = route;
        this.authService = authService;
    }
    AuthComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.paramMap.subscribe(function (params) {
            var param = params.get('data');
            if (param == 'logout') {
                _this.authService.signOut();
            }
            else if (param == 'reset') {
                _this.authService.resetPassword().pipe(take(1)).subscribe(function () {
                    window.location.replace(environment.gmailUri);
                }, function (err) { console.error(err); });
            }
        });
    };
    return AuthComponent;
}());
export { AuthComponent };
