
    <ng-container *ngIf="authService.claims == null; else signedIn">
      <div class="loading">
          <img src="assets/loading.svg" alt="loading">
      </div>
    </ng-container>

    <ng-template #signedIn>
      <router-outlet></router-outlet>
    </ng-template>
  