import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';
import { OAuthService, AuthConfig, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { from as observableFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  claims: object;
  oauthConfig: AuthConfig;
  mobile = (/iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase()));

  constructor(
    private oauthService: OAuthService,
    private http: HttpClient
  ) {
    this.oauthConfig = environment.oAuthConfig;
    this.oauthConfig.redirectUri = window.location.origin;
    this.oauthConfig.silentRefreshRedirectUri = Location.joinWithSlash(window.location.origin, '/assets/silent-refresh.html');

    // let logoutRedirectUri: string = Location.joinWithSlash(environment.basecampUri, '/logout');
    this.oauthConfig.logoutUrl =
      `${this.oauthConfig.issuer}v2/logout?client_id=${this.oauthConfig.clientId}&returnTo=${encodeURIComponent(this.oauthConfig.redirectUri)}`;

    console.log('Setting OAUTH configuration', this.oauthConfig);
    this.oauthService.setStorage(localStorage);
    this.oauthService.configure(this.oauthConfig);

    this.oauthService.events.subscribe((e) => {
      this.handleAuthEvent(e);
    });

    observableFrom(this.oauthService.loadDiscoveryDocumentAndLogin()).pipe(
      map((signedIn: boolean) => {

        if (signedIn) {
          this.claims = this.oauthService.getIdentityClaims();
          console.log('Claims : ', this.claims);

          if (!this.claims) return;

          if (this.mobile) {
            // 운영: donation.org, 개발: donation.org 도메인을 사용하는 계정은 현장기증접수로 이동
            if ((<any>this.claims).email.match(environment.selfDonationDomain)) {
              window.location.replace(Location.joinWithSlash(environment.basecampUri, '/selfdonation'));
            }
            else {
              window.location.replace(environment.gmailUri);
            }
          }
          else {
            // 운영: beautifulstore.org, 개발: beautifulstore.org 도메인을 사용하는 계정은 그룹웨어로 이동
            // inslab 계정은 구글드라이브로 이동
            if ((<any>this.claims).email.match(environment.groupwareDomain)) {
              const inslabEmail: Array<string> = [
                "inslab", "minchulahn", "heungyeonlee", "echan1020", "jaebongchun", "jhlee", "tez1006", "jylee", "sunchanlee"
              ];

              if (inslabEmail.findIndex(value => (<any>this.claims).email.match(value)) == -1) {
                window.location.replace(environment.groupwareUri);
              } else {
                window.location.replace(environment.googleDriveUri);
              }
            }
            else {
              // 운영: 1004.org, 개발: 1004.org, iotoi.io 도메인을 사용하는 계정은 베이스캠프로 이동
              if (environment.basecampDomain.findIndex(value => (<any>this.claims).email.match(value)) != -1) {
                window.location.replace(environment.basecampUri);
              }
              else {
                alert("관리자에게 문의하세요.");
                this.signOut();
              }
            }
          }
        }
      })
    ).subscribe(
      () => { },
      (err) => {
        // TODO: Need to handle authentication failed case
        this.handleAuthEvent(err);
      },
    );

    this.oauthService.setupAutomaticSilentRefresh();
  }

  handleAuthEvent(e: any): void {
    if (e instanceof OAuthErrorEvent) {
      if (e.type === 'silent_refresh_error' || e.type === 'token_error') {
        console.log(`Signing out user forcefully due to ${e.type}`);
        this.signOut();
      }
      console.error(e);
    } else {
      console.log(e);
    }
  }

  signOut() {
    this.oauthService.logOut();
  }

  resetPassword() {
    if (!this.claims) return null;

    const resetUrl = Location.joinWithSlash(this.oauthConfig.issuer, 'dbconnections/change_password');
    let emailAddress: string = (<any>this.claims).email;

    return this.http.post(
      resetUrl,
      {
        client_id: this.oauthConfig.clientId,
        email: emailAddress,
        connection: 'Username-Password-Authentication'
      },
      {
        headers: new HttpHeaders({ 'content-type': 'application/json' }),
        responseType: 'text'
      },
    );
  }

}
