export const environment = {
  production: false,
  oAuthConfig: {
    issuer: 'https://bs-test.auth0.com/',
    clientId: 'kJbKunh5hdzdISMIi3t9Mc8Kd80BYu1N',
    scope: 'openid profile email'
  },
  basecampDomain: ['1004.org', 'iotoi.io', 'temporary.org'],
  groupwareDomain: 'beautifulstore.org',
  selfDonationDomain: 'donation.org',
  gmailUri: 'https://mail.google.com/a/iotoi.io',
  googleDriveUri: 'https://drive.google.com/a/iotoi.io',
  groupwareUri: 'https://drive.google.com/a/iotoi.io',
  basecampUri: 'https://basecamp-test.beautifulstore.org',
  logisticsProcessUri: 'https://basecamp-test.beautifulstore.org/donation/m-logistics-process'
};
