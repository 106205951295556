import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';
import { OAuthService, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { from as observableFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "angular-oauth2-oidc";
import * as i2 from "@angular/common/http";
var AuthService = /** @class */ (function () {
    function AuthService(oauthService, http) {
        var _this = this;
        this.oauthService = oauthService;
        this.http = http;
        this.mobile = (/iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase()));
        this.oauthConfig = environment.oAuthConfig;
        this.oauthConfig.redirectUri = window.location.origin;
        this.oauthConfig.silentRefreshRedirectUri = Location.joinWithSlash(window.location.origin, '/assets/silent-refresh.html');
        // let logoutRedirectUri: string = Location.joinWithSlash(environment.basecampUri, '/logout');
        this.oauthConfig.logoutUrl =
            this.oauthConfig.issuer + "v2/logout?client_id=" + this.oauthConfig.clientId + "&returnTo=" + encodeURIComponent(this.oauthConfig.redirectUri);
        console.log('Setting OAUTH configuration', this.oauthConfig);
        this.oauthService.setStorage(localStorage);
        this.oauthService.configure(this.oauthConfig);
        this.oauthService.events.subscribe(function (e) {
            _this.handleAuthEvent(e);
        });
        observableFrom(this.oauthService.loadDiscoveryDocumentAndLogin()).pipe(map(function (signedIn) {
            if (signedIn) {
                _this.claims = _this.oauthService.getIdentityClaims();
                console.log('Claims : ', _this.claims);
                if (!_this.claims)
                    return;
                if (_this.mobile) {
                    // 운영: donation.org, 개발: donation.org 도메인을 사용하는 계정은 현장기증접수로 이동
                    if (_this.claims.email.match(environment.selfDonationDomain)) {
                        window.location.replace(Location.joinWithSlash(environment.basecampUri, '/selfdonation'));
                    }
                    else {
                        window.location.replace(environment.gmailUri);
                    }
                }
                else {
                    // 운영: beautifulstore.org, 개발: beautifulstore.org 도메인을 사용하는 계정은 그룹웨어로 이동
                    // inslab 계정은 구글드라이브로 이동
                    if (_this.claims.email.match(environment.groupwareDomain)) {
                        var inslabEmail = [
                            "inslab", "minchulahn", "heungyeonlee", "echan1020", "jaebongchun", "jhlee", "tez1006", "jylee", "sunchanlee"
                        ];
                        if (inslabEmail.findIndex(function (value) { return _this.claims.email.match(value); }) == -1) {
                            window.location.replace(environment.groupwareUri);
                        }
                        else {
                            window.location.replace(environment.googleDriveUri);
                        }
                    }
                    else {
                        // 운영: 1004.org, 개발: 1004.org, iotoi.io 도메인을 사용하는 계정은 베이스캠프로 이동
                        if (environment.basecampDomain.findIndex(function (value) { return _this.claims.email.match(value); }) != -1) {
                            window.location.replace(environment.basecampUri);
                        }
                        else {
                            alert("관리자에게 문의하세요.");
                            _this.signOut();
                        }
                    }
                }
            }
        })).subscribe(function () { }, function (err) {
            // TODO: Need to handle authentication failed case
            _this.handleAuthEvent(err);
        });
        this.oauthService.setupAutomaticSilentRefresh();
    }
    AuthService.prototype.handleAuthEvent = function (e) {
        if (e instanceof OAuthErrorEvent) {
            if (e.type === 'silent_refresh_error' || e.type === 'token_error') {
                console.log("Signing out user forcefully due to " + e.type);
                this.signOut();
            }
            console.error(e);
        }
        else {
            console.log(e);
        }
    };
    AuthService.prototype.signOut = function () {
        this.oauthService.logOut();
    };
    AuthService.prototype.resetPassword = function () {
        if (!this.claims)
            return null;
        var resetUrl = Location.joinWithSlash(this.oauthConfig.issuer, 'dbconnections/change_password');
        var emailAddress = this.claims.email;
        return this.http.post(resetUrl, {
            client_id: this.oauthConfig.clientId,
            email: emailAddress,
            connection: 'Username-Password-Authentication'
        }, {
            headers: new HttpHeaders({ 'content-type': 'application/json' }),
            responseType: 'text'
        });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.OAuthService), i0.inject(i2.HttpClient)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
