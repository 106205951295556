import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthService } from './auth.service';
import { take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html'
})
export class AuthComponent implements OnInit {

  // public groupwareUri: string = environment.groupwareUri;
  // public logisticsProcessUri: string = environment.logisticsProcessUri;

  constructor(
    private route: ActivatedRoute,
    public authService: AuthService
  ) { }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      let param: string = params.get('data');

      if (param == 'logout') {
        this.authService.signOut();
      }
      else if (param == 'reset') {
        this.authService.resetPassword().pipe(
          take(1)
        ).subscribe(
          () => {
            window.location.replace(environment.gmailUri);
          },
          (err: HttpErrorResponse) => { console.error(err); }
        );
      }
    });
  }

}
