import { Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
var routes = [
    { path: '', component: AuthComponent },
    { path: ':data', component: AuthComponent },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
